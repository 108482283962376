import { AutosSurcharges } from '@kijiji/generated/graphql-types'
import { useTranslation } from 'next-i18next'
import { type FC } from 'react'
import { useTheme } from 'styled-components'

import { TRANSLATION_KEYS } from '@/constants/localization'
import { BodyText } from '@/ui/atoms/body-text'
import { type BodyTextSize } from '@/ui/atoms/body-text/BodyText'

export type ListingSurcharges = {
  /**
   * The surcharges to display.
   */
  surcharges?: AutosSurcharges

  /**
   * The size of the text.
   */
  size?: BodyTextSize
}

export const ListingSurcharges: FC<ListingSurcharges> = ({ surcharges, size = 'xSmall' }) => {
  const { t } = useTranslation(TRANSLATION_KEYS.LISTING)
  const { colors } = useTheme()

  const getSurcharges = () => {
    if (surcharges === AutosSurcharges.PlusTaxesLuxurySurcharge) {
      return t('listing:price.plus_taxes_luxury_surcharge')
    }
    if (surcharges === AutosSurcharges.PlusTaxes) {
      return t('listing:price.plus_taxes')
    }

    if (surcharges === AutosSurcharges.PlusGst) {
      return t('listing:price.plus_gst')
    }

    return null
  }

  const surchargesLabel = getSurcharges()

  return surchargesLabel ? (
    <BodyText color={colors.grey.light1} size={size} data-testid="listing-surcharges">
      {surchargesLabel}
    </BodyText>
  ) : null
}
