import { type ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { BaseListingCardContainer } from '@/components/srp/listing-card/styled'
import { zIndexRegistry } from '@/constants/zIndexRegistry'
import { BodyText } from '@/ui/atoms/body-text'
import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

export const MIN_CORE_CARD_WIDTH = '14.4rem'
export const MAX_CORE_CARD_WIDTH = '26.7rem'

export const CoreListingCardContainer = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  border: .1rem solid ${theme.colors.grey.light4};
  box-shadow: ${theme.boxShadow['shadow-1']};
  overflow: hidden;
  width: fit-content;

  & > a {
    display: grid;
    height: 100%;
  }
`
)

const feedImageStyle = (theme: ThemeProps) => `
  min-width: unset;

  ${MEDIA_QUERIES(theme).medium} {
    max-width: ${MAX_CORE_CARD_WIDTH};
    min-width: ${MIN_CORE_CARD_WIDTH};
  }
`

export const CoreListingCardImageContainer = styled.div(
  ({ theme }) => `
  background-color: ${theme.colors.white};
  height: 100%;`
)

// eslint-disable-next-line @kijiji/prefer-image-custom
export const CoreListingCardImage = styled.img<{ variant?: 'carousel' | 'feed' }>(
  ({ theme, variant }) => `
  align-self: center;
  aspect-ratio: 4 / 3;
  max-width: ${MAX_CORE_CARD_WIDTH};
  min-width: ${MIN_CORE_CARD_WIDTH};
  object-fit: cover;
  width: 100%;

  ${variant === 'feed' ? feedImageStyle(theme) : ''}
`
)

export const CoreListingCardBody = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.mini};
  justify-content: space-between;
  max-width: ${MAX_CORE_CARD_WIDTH};
  min-width: ${MIN_CORE_CARD_WIDTH};
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.default};
  width: 100%;
  background-color: ${theme.colors.white};
`
)

export const CoreListingCardTitle = styled(BodyText)(
  ({ theme }) => `
  /* 
   * Fallback for unsupported browsers (like Firefox)
   * It will wrap in 1 line for browsers that don't support line-clamp.
   */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    /* WebKit-specific one-line ellipsis */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    text-overflow: ellipsis;
    white-space: normal; /* Allow multiple lines for supported browsers */
  }

  ${MEDIA_QUERIES(theme).medium}{
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      /* WebKit-specific two-line ellipsis */
      -webkit-line-clamp: 2;
    }  
  }
`
)

export const CoreListingCardAttributes = styled(BodyText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

/** LOADING LISTING CARD */
export const LoadingCoreCardContainer = styled.div(
  ({ theme }) => `
  width: 100%;
  background: ${theme.colors.white};
`
)

export const LoadingCoreCardBody = styled.div(
  ({ theme }) => `
  border-radius: ${theme.borderRadius.small};
  box-shadow: 0 .1rem .1rem 0 rgba(0,0,0,.1);
  height: 12rem;
  padding: ${theme.spacing.defaultSmall} ${theme.spacing.defaultSmall} ${theme.spacing.default};
`
)

/** CORE LISTING FEED CARD */
export const CoreListingFeedCardContainer = styled(BaseListingCardContainer)(
  ({ theme }) => `
  ${MEDIA_QUERIES(theme).medium}{
    gap: ${theme.spacing.default};
    padding: ${theme.spacing.default};
  }
`
)

// eslint-disable-next-line @kijiji/prefer-link-custom
export const CoreListingCardLink = styled.a(
  ({ theme }) => `
  color: ${theme.colors.grey.primary};
  text-decoration: none;

  &::before {
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: ${zIndexRegistry.CARD};
  }

  &:visited {
    color: ${theme.colors.violet.primary};
  }
`
)

export const CoreListingCardHeading = styled(BodyText)(
  ({ theme }) => `
  padding: 0.2rem; /* To make the a11y selectable border box visible  */
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.large)};
    text-overflow: ellipsis;
    white-space: nowrap;
    display: revert;
  }
`
)

export const CoreListingCardFeedImageContainer = styled.div(
  ({ theme }) => `
  height: auto;
  width: 10rem;
  flex-shrink: 0;
  
  ${MEDIA_QUERIES(theme).medium}{
    height: 17.6rem;
    width: 17.6rem;
  }
`
)
