import { format, parseISO } from 'date-fns'

import { getLocale, ROUTE_LOCALE } from '@/domain/locale'

/** Define format string based on locale and set time */
const dateFormatString = (): string => {
  const { routeLocale } = getLocale()
  const isFrench = routeLocale === ROUTE_LOCALE.fr
  return isFrench ? 'd MMMM yyyy' : 'MMMM d, yyyy'
}

const getUTCDate = (currDate: Date) => {
  const date = currDate.getUTCDate()
  const month = currDate.getUTCMonth()
  const year = currDate.getUTCFullYear()

  return { date, month, year }
}

export const formatStartEndDate = (start: string, end?: string | null): string => {
  // Use parseISO to reliably parse the date string
  const { date: startDate, month: startMonth, year: startYear } = getUTCDate(parseISO(start))

  // Format the start date
  const utcStartDate = new Date(startYear, startMonth, startDate)
  const formattedStartDate = format(utcStartDate, dateFormatString())

  // Format the end date if it exists
  let formattedEndDate = null
  if (end) {
    const { date: endDate, month: endMonth, year: endYear } = getUTCDate(parseISO(end))

    const utcEndDate = new Date(endYear, endMonth, endDate)
    formattedEndDate = format(utcEndDate, dateFormatString())
  }

  /** If the attribute is of type date-range */
  if (formattedStartDate && formattedEndDate) {
    return `${formattedStartDate} - ${formattedEndDate}`
  }

  return formattedStartDate
}

export const formatDateTime = (date: string, dateTime?: string | null): string => {
  const formattedDate = formatStartEndDate(date)

  if (dateTime) {
    const formattedTime = format(dateTime, 'h:mm aaa')
    return `${formattedDate} ${formattedTime}`
  }

  return formattedDate
}
