import { type LanguageKey } from '@/domain/locale'
import { type AdsenseSlot } from '@/features/advertisement/constants/adsense'
import { type BidConfig, type PrebidConfig } from '@/features/advertisement/constants/prebid'
import { type AmazonTamConfig } from '@/features/advertisement/constants/tam'

export type AdSizesArray = [number, number][]
export type AdSizesMap = { [key: string]: AdSizesArray }

export type SizeMappings = {
  [key: string]: {
    [key: string]: AdSizesArray
  }
}

export type SizeMappingsGPT = Record<
  string,
  {
    viewPortSize: [number, number]
    sizes: AdSizesArray
  }[]
>

type LazyLoadConfigObject = {
  marginPercent: number
  mobileScaling: number
}

type EnableLazyLoad = boolean | LazyLoadConfigObject

export type MediaTypes = {
  banner: {
    sizes: AdSizesArray
  }
}

export type PrebidSizeConfig = {
  mediaQuery: string
  sizesSupported: AdSizesArray
  labels: string[]
}

type UserSync = {
  iframeEnabled: boolean
  filterSettings: {
    iframe: {
      bidders: string
      filter: string
    }
  }
}

export type GptGlobalProperties = {
  path: string
  enableLazyLoad?: EnableLazyLoad
  prebid?: {
    bidderTimeout: number
    priceGranularity: string
    bidderSequence: string
    sizeConfig?: PrebidSizeConfig[]
    userSync?: UserSync
  }
  usePrebid?: boolean
  aps?: AmazonTamConfig
  useAPS?: boolean
  sizeMappings?: SizeMappingsGPT
}

export type GptTargetingProps = Record<string, string | string[] | undefined | null>

export type GptAdSlot = {
  id: string
  position?: number
  path?: string
  collapseEmptyDiv?: [boolean, boolean]
  enableLazyLoad?: EnableLazyLoad
  sizeMappingName?: string
  sizes: AdSizesArray
  prebid?: PrebidConfig[]
  targeting?: GptTargetingProps
  metaData?: Record<string, unknown>
}

export type GptSlots = GptAdSlot & {
  bidderSequence?: string
  bidderTimeout?: number
  priceGranularity?: string
  g_hd?: string
  li?: string
  mediaTypes: MediaTypes
  pagetype?: string
  pos?: string
  pt: string
  ptg?: string
  bids?: BidConfig[]
}

type AdSettings = GptSlots | AdsenseSlot

export const isGptSlots = (slots: AdSettings): slots is GptSlots =>
  (slots as GptSlots).mediaTypes !== undefined

export type GotAdSettingsSlot = {
  settingsId: string
  adType: GotAdType
  settings: AdSettings
}

export type GotAdType = 'display_prebid' | 'afs_native'

export type AdConfig = GptGlobalProperties & {
  targeting: GptTargetingProps
  slots: GptAdSlot[]
}

type PageSettings = {
  pageType: string
  categoryIds: string[]
}
export type GotDynamicConditions = {
  lang: LanguageKey
  mode: 'light' | 'dark'
}

export type GotApiResponse = {
  sourceSnapshotId: string
  experimentGroups: Record<string, unknown>
  dynamicConditions: GotDynamicConditions
  adSettings: GotAdSettingsSlot[]
  pageSettings?: PageSettings[]
}

export type GptValue = string | string[] | null | undefined

export type GptTargeting = {
  key: string
  value: GptValue
}

export type FilterName =
  | 'adTypeSection'
  | 'forsaleby'
  | 'make'
  | 'bodytype'
  | 'priceSection'
  | 'year'

export type ForSaleBy = 'delr' | 'ownr'

export const isForSaleBy = (value: string): value is ForSaleBy =>
  value === 'delr' || value === 'ownr'
