import {
  getAdSourceForPromotionListing,
  shouldShowPriceDrop,
} from '@/components/srp/search-list/domain'
import { getListingBadgeLabel } from '@/domain/listings/getListingBadgeLabel'
import { isAmountPrice } from '@/domain/listings/isAmountPrice'
import { type GenericListing } from '@/features/listing/types/listings'
import { DATALAYER_SRP_FLAG_MAP } from '@/lib/ga/constants/datalayer'
import { GA_AD_TYPE_VALUE, isKeyOfGAAdType } from '@/lib/ga/constants/ga'
import { type DataLayerCategory, type SearchImpressions } from '@/lib/ga/types/dataLayer'
import { getSearchPageType } from '@/lib/ga/utils/getSearchPageType'
import { removeUndefinedFromObject } from '@/utils/removeUndefinedFromObj'
import { convertToLowerCase, removeSpaces, replaceAmpersands } from '@/utils/string'

const getListingFlags = (listing: GenericListing): string[] => {
  const flagsList: string[] = []
  const { categoryId, price } = listing
  const flags = 'flags' in listing ? listing.flags : undefined

  if (!flags) return flagsList

  const badgeValue = getListingBadgeLabel({
    categoryId,
    categorySpecificBadge: !!flags.categorySpecificBadge,
  })

  const originalAmount = isAmountPrice(price) ? (price.originalAmount ?? undefined) : undefined
  const hasPriceDrop =
    'priceDrop' in flags ? shouldShowPriceDrop(!!flags.priceDrop, originalAmount) : false

  if (hasPriceDrop) flagsList.push(DATALAYER_SRP_FLAG_MAP['priceDrop'])
  if (badgeValue) flagsList.push(DATALAYER_SRP_FLAG_MAP[badgeValue])

  Object.keys(flags).forEach((key: string) => {
    if (key === 'priceDrop' || key === 'categorySpecificBadge') return

    if (typeof key === 'string') {
      const gaMappedValue = DATALAYER_SRP_FLAG_MAP[key]
      if (gaMappedValue) flagsList.push(gaMappedValue)
    }
  })

  return flagsList
}

const formatCategoryName = (name?: string): string | undefined => {
  if (!name) return undefined

  return removeSpaces(replaceAmpersands(convertToLowerCase([name])))[0]
}

export const getSearchImpressions = ({
  datalayerCategory,
  isSeeAllTopAd,
  keywords,
  listings,
}: {
  datalayerCategory?: DataLayerCategory['c']
  /** Is query parameter "gpTopAds" present */
  isSeeAllTopAd?: boolean
  keywords?: string
  listings: GenericListing[]
}): SearchImpressions => {
  const searchImpressions: SearchImpressions = listings.map((listing, index) => {
    const formattedFlags = getListingFlags(listing)

    const flags = 'flags' in listing ? listing.flags : {}

    const adSourcePromoListing = getAdSourceForPromotionListing(
      flags,
      listing.adSource,
      isSeeAllTopAd
    )
    const listingAdSource = adSourcePromoListing ?? listing.adSource

    const promotionName = isKeyOfGAAdType(listingAdSource)
      ? GA_AD_TYPE_VALUE[listingAdSource]
      : GA_AD_TYPE_VALUE.ORGANIC

    return removeUndefinedFromObject({
      quantity: 1,
      promotion_name: promotionName,
      price: isAmountPrice(listing.price) ? listing.price.amount : undefined,
      item_name: listing.title,
      item_list_name: getSearchPageType(keywords),
      item_id: listing.id,
      item_category: formatCategoryName(datalayerCategory?.c.n),
      item_category3: formatCategoryName(datalayerCategory?.l3?.n),
      item_category2: formatCategoryName(datalayerCategory?.l2?.n),
      item_category1: formatCategoryName(datalayerCategory?.l1?.n),
      item_badge: formattedFlags.length ? formattedFlags : undefined,
      index: index + 1,
    })
  })

  return searchImpressions
}
