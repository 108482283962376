import { type ALL_SIZES, type EXTENSION, getImageBySize } from '@kijiji/image'
import Image, { type ImageProps } from 'next/image'
import { useState } from 'react'

import { IMAGE_NOT_AVAILABLE_URL } from '@/constants/others'

export type ImageYAMSProps = Omit<ImageProps, 'src'> & {
  src?: string | null | undefined
  extension?: EXTENSION
  width?: ALL_SIZES
  /**
   * Specifies if the component should render a plain "img" tag instead of next/image
   */
  skipNextImage?: boolean
}

export const prepareImage = (
  src: ImageYAMSProps['src'],
  width: ImageYAMSProps['width'],
  extension: ImageYAMSProps['extension']
): string => {
  if (!src) {
    return IMAGE_NOT_AVAILABLE_URL
  }
  /** Only external images should be passed as string. Static images should be of type StaticImageData **/
  if (typeof src === 'string') {
    return getImageBySize(src, width || 200, extension, true)
  }

  return src
}

/**
 * A custom wrapper around Next.js Image component to provide support for custom image formats and sizes
 *
 * @example
 *  // Use ImageYAMS like you would use Next.js Image component:
 *  <ImageYAMS src="/path/to/image.jpg" alt="An image" width={200} height={200} extension="WEBP" />
 *
 * @param {ImageProps & ImageYAMSProps} props - The props to pass to the ImageYAMS component
 * @returns {ReactElement} The rendered ImageCustom component
 */
export const ImageYAMS: React.FC<ImageYAMSProps> = ({
  src,
  width,
  extension = 'JPG',
  skipNextImage,
  priority,
  ...rest
}) => {
  const [imageSrc, setImageSrc] = useState<string>(prepareImage(src, width, extension))

  const modifiedProps: ImageProps = {
    src: imageSrc,
    ...(width && { width }),
    ...(typeof src === 'string' && { unoptimized: true }),
    ...rest,
    onError: () => {
      setImageSrc(IMAGE_NOT_AVAILABLE_URL)
    },
  }

  if (skipNextImage) {
    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        src={imageSrc}
        {...rest}
        alt={rest.alt ?? ''}
        loading={priority ? 'eager' : 'lazy'}
        //Bypass TS as it doesn't map "fetchpriority" properly
        {...{ fetchpriority: priority ? 'high' : 'low' }}
      />
    )
  }

  return <Image {...modifiedProps} alt={rest.alt ?? ''} priority={priority} />
}
