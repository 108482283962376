import { CATEGORIES } from '@kijiji/category'
import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { type TFunction } from 'i18next'

import { type CoreListingCardData } from '@/components/shared/core-listing-card/CoreListingCard'
import { getLocale, ROUTE_LOCALE } from '@/domain/locale'
import { ATTRIBUTES } from '@/features/attributes/constants/attributes'
import { type GenericListing } from '@/features/listing/types/listings'

const CARS_SUPPORTED_CATEGORIES = [CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID]
const REAL_ESTATE_SUPPORTED_CATEGORIES = [
  CATEGORIES.APARTMENTS_CONDOS_CATEGORY_ID,
  CATEGORIES.CONDOS_FOR_SALE_CATEGORY_ID,
  CATEGORIES.HOUSES_FOR_SALE_CATEGORY_ID,
]

/**
 * Format details of a listing into a single line text.
 * It will get the specific data for Autos and RE (Supported categories only), and generic data for all other categories.
 *
 * @param listing - listing object that could be a core listing, search listing or generic one
 * @param t - Translation for listings
 * @param skipLocationName - It will not append location name to the details
 *
 * @returns an one line text with listing details for a specific category
 */
export const formatListingDetails = (
  listing: GenericListing | CoreListingCardData,
  t: TFunction,
  skipLocationName?: boolean
) => {
  const { routeLocale } = getLocale()
  const isFrench = routeLocale === ROUTE_LOCALE.fr

  const { categoryId, location } = listing
  const attributes: Partial<ListingAttributeV2 | null>[] =
    'attributes' in listing ? (listing.attributes?.all ?? []) : []

  let details = ''

  /** Attributes to display for cars & trucks */
  if (CARS_SUPPORTED_CATEGORIES.includes(categoryId)) {
    const mileageInKm = attributes?.find((item) => item?.canonicalName === ATTRIBUTES.CAR_MILEAGE)

    /** Search won't have the "values" field returned, but the "canonicalValues" instead  */
    const mileageValue = mileageInKm?.values?.[0] ?? mileageInKm?.canonicalValues?.[0]

    if (mileageValue) details = `${formatWholeNumber(parseInt(mileageValue), !isFrench)}km`
  }

  /** Attributes to display for Long Term Rentals & Houses and Condos for Sale */
  if (REAL_ESTATE_SUPPORTED_CATEGORIES.includes(categoryId)) {
    const areaInFeet = attributes?.find((item) => item?.canonicalName === ATTRIBUTES.AREA_IN_FEET)

    /** Search won't have the "values" field returned, but the "canonicalValues" instead  */
    const areaInFeetValue = areaInFeet?.values?.[0] ?? areaInFeet?.canonicalValues?.[0]

    if (areaInFeetValue)
      details = `${formatWholeNumber(parseInt(areaInFeetValue), !isFrench)} ${t('listing:attribute.areainfeet.unit')}`
  }

  if (!skipLocationName && location?.name) {
    /** No matter which category, it will append the location after the attributes */
    details += details ? ` • ${location.name}` : location.name
  }

  return details
}
