import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'
import { formatWholeNumber } from '@kijiji/number/formatWholeNumber'
import { type TFunction } from 'next-i18next'

import { getLocale, ROUTE_LOCALE } from '@/domain/locale'
import {
  BOOLEAN_NUMERIC_VALUES,
  BOOLEAN_TEXT_VALUES,
} from '@/features/attributes/constants/attributes'
import { formatAttributeLabel } from '@/features/attributes/utils/formatAttributeLabel'
import { formatDateTime, formatStartEndDate } from '@/utils/date/formatDateTime'
import { isNumber } from '@/utils/types'

/**
 * Label override for multiple concatenated attributes
 */
export type ConcatenatedLabelOverride = (
  t: TFunction,
  attributesToConcatenate: ListingAttributeV2[]
) => string

/**
 * Label override for "name" of the attribute
 */
export type NameOverrideProps = ({ t }: { t: TFunction }) => string

/** Generic label override for values displayed for attributes */
export type LabelOverrideProps = (props: {
  attribute: ListingAttributeV2
  t: TFunction
  /**
   * Should be used only when the label override needs a custom translation AND the key to be used is not the same as the canonical name of the attribute.
   * This happens when multiple categories have different name for the same attribute.
   */
  customIntlKey?: string
}) => string

type LabelOverrideTypes =
  | 'BOOLEAN_NAME'
  | 'FORMAT_DATE'
  | 'FORMAT_NUMBER'
  | 'INCLUDED'
  | 'JOIN_ALL_VALUES'
  | 'NOT_AVAILABLE'
  | 'REPLACE_TRUE_VALUE_WITH_TRANSLATION'
  | 'REPLACE_VALUE_WITH_TRANSLATION'
  | 'VALUE_PLUS_NAME'
type AttributesLabelOverride = { [x in LabelOverrideTypes]: LabelOverrideProps }

type ConcatenatedLabelOverrideTypes = 'FORMAT_DATE_TIME' | 'FORMAT_RANGE_DATE_TIME'
type ConcatenatedAttributesLabelOverride = {
  [x in ConcatenatedLabelOverrideTypes]: ConcatenatedLabelOverride
}

export const isFalseValue = (value?: string) => {
  return !value || value === BOOLEAN_TEXT_VALUES.NO || value === BOOLEAN_NUMERIC_VALUES.NO
}

export const CONCATENATED_LABEL_OVERRIDE: ConcatenatedAttributesLabelOverride = {
  FORMAT_DATE_TIME: (_, [date, time]) => {
    const dateValue = date.values[0]
    const timeValue = time?.values[0]

    if (!dateValue) return ''
    return formatDateTime(dateValue, timeValue)
  },
  FORMAT_RANGE_DATE_TIME: (t, [dateFrom, dateTo]) => {
    const labelFrom = `${t('listing:attribute.range.from')} ${formatAttributeLabel(dateFrom)}`
    if (!dateTo) return labelFrom

    const labelTo = formatAttributeLabel(dateTo)
    return `${labelFrom} ${t('listing:attribute.range.to')} ${labelTo}`
  },
}

export const ATTRIBUTES_LABEL_OVERRIDE: AttributesLabelOverride = {
  REPLACE_TRUE_VALUE_WITH_TRANSLATION: ({
    attribute: { canonicalName, canonicalValues },
    t,
    customIntlKey,
  }) => {
    const value = canonicalValues?.[0]
    if (isFalseValue(value)) return ''

    return t(`listing:attribute.label_override.${customIntlKey ?? canonicalName}`)
  },
  REPLACE_VALUE_WITH_TRANSLATION: ({
    attribute: { canonicalName, canonicalValues, values },
    t,
    customIntlKey,
  }) => {
    const value = values?.[0] ?? canonicalValues?.[0]
    if (!value) return ''

    return t(`listing:attribute.label_override.${customIntlKey ?? canonicalName}`, { value })
  },
  FORMAT_DATE: ({ attribute: { values } }) => {
    const startDate = values[0]
    if (!startDate) return ''

    const endDate = values[1]
    const formattedDate = formatStartEndDate(startDate, endDate)

    return formattedDate
  },
  FORMAT_NUMBER: ({ attribute: { values } }) => {
    const { routeLocale } = getLocale()
    const value = parseInt(values[0] ?? '')

    if (!isNumber(value)) return values[0] ?? ''

    const isEnglish = routeLocale === ROUTE_LOCALE.en

    return formatWholeNumber(value, isEnglish)
  },
  BOOLEAN_NAME: ({ attribute: { name, canonicalValues } }) => {
    const value = canonicalValues?.[0]
    if (isFalseValue(value)) return ''
    return name ?? ''
  },
  INCLUDED: ({ attribute: { canonicalValues, name }, t }) => {
    const value = canonicalValues?.[0]
    if (isFalseValue(value)) return ''

    return t(`listing:attribute.label_override.included`, { name })
  },
  NOT_AVAILABLE: ({ attribute: { values, canonicalValues }, t }) => {
    const value = values[0] ?? canonicalValues?.[0]

    if (value === BOOLEAN_NUMERIC_VALUES.NO || value === BOOLEAN_TEXT_VALUES.NO)
      return t('listing:attribute.label_override.not_available')

    return ''
  },
  VALUE_PLUS_NAME: ({ attribute: { values, name } }) => {
    return `${values[0]} ${name}`
  },
  JOIN_ALL_VALUES: ({ attribute: { values, canonicalValues } }) => {
    if (values.length) {
      return values.join(', ')
    }

    return canonicalValues?.join(', ') ?? ''
  },
}
