export const isValidDate = (dateString: string) => {
  // Check if the date string matches a specific format (e.g., YYYY-MM-DD)
  // Regular expression to match ISO 8601 date format (YYYY-MM-DDTHH:mm:ssZ)
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/

  // Check if the date string matches the ISO format
  if (!iso8601Regex.test(dateString)) {
    return false
  }

  // Parse the date and check if it's a valid date
  const date = new Date(dateString)
  return !isNaN(date.getTime())
}
