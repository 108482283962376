import { type NextRequest, type NextResponse } from 'next/server'
import { v4 as generateUUID } from 'uuid'

import { CookieRegistry } from '@/constants/cookieRegistry'
import { getCookieSubDomain } from '@/utils/cookies/getCookieSubDomain'
import {
  getMaxSafeExpiryInDays,
  MAX_INTEGER_SAFE_MAX_AGE,
} from '@/utils/cookies/getMaxSafeAgeExpiry'
import { sendToLogger } from '@/utils/sendToLogger'

export const MACHINE_ID_COOKIE_EXPIRY_MS = MAX_INTEGER_SAFE_MAX_AGE

export function setMachineIdCookie(req: NextRequest, res: NextResponse) {
  const { cookies } = req

  const machIdCookie = cookies.get(CookieRegistry.MACHINE_ID)

  try {
    const machIdUuid =
      machIdCookie && machIdCookie?.value?.length > 0 ? machIdCookie?.value : generateUUID()
    const domain = getCookieSubDomain(req.headers.get('host') || req.headers.get('referer') || '')

    const date = new Date()
    const daysUntilMaxSafeExpiry = getMaxSafeExpiryInDays()
    date.setTime(date.getTime() + daysUntilMaxSafeExpiry * 24 * 60 * 60 * 1000)

    // Prepare the response with the updated cookie
    res.cookies.set(CookieRegistry.MACHINE_ID, machIdUuid, {
      domain,
      path: '/',
      httpOnly: false,
      expires: date,
      maxAge: MACHINE_ID_COOKIE_EXPIRY_MS,
      secure: true,
    })
  } catch (e) {
    sendToLogger(e, {
      tags: { fn: 'setMachineIdCookie' },
      fingerprint: ['setMachineIdCookie'],
    })
  }

  return res
}
