import { type AuthModalState, GetAuthModalStateDocument } from '@kijiji/generated/graphql-types'

import { useApollo } from '@/lib/apollo/apolloClient'

export const useSignInModal = () => {
  const { cache } = useApollo()

  const openSignInModal = (authModalState: Omit<AuthModalState, 'isModalOpen'> = {}) => {
    cache.writeQuery({
      query: GetAuthModalStateDocument,
      data: {
        authModalState: {
          isModalOpen: true,
          modalVariant: authModalState.modalVariant || null,
          callbackUrl: authModalState.callbackUrl || null,
          categoryId: authModalState.categoryId ?? null,
        },
      },
    })
  }

  return { openSignInModal }
}
