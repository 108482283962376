import { type ListingAttributeV2 } from '@kijiji/generated/graphql-types'

import { formatStartEndDate } from '@/utils/date/formatDateTime'
import { isValidDate } from '@/utils/date/isValidDate'

/**
 * This logic handles all types of attributes and identifies what to display as a label for each one based on what the BE returns to us.
 * If we to display a different label from what the API is returned, then that custom logic should be added to the ATTRIBUTES_KEYS constant under the "overrideLabel" key.
 */
export const formatAttributeLabel = (attribute: ListingAttributeV2) => {
  const { values } = attribute

  const value = values[0]
  if (!value) return ''

  /** Check if the attribute value is of type date-time*/
  const isDateTimeValue = isValidDate(value)
  if (isDateTimeValue) {
    return formatStartEndDate(value, values[1])
  }

  /**
   * In Any other case (number or text) we should show the "values" separated by a comma
   */
  return values.join(', ')
}
