import styled from 'styled-components'

import { MEDIA_QUERIES } from '@/ui/constants/mediaQueries'
import { hexToRgb } from '@/ui/helpers/hexToRgb'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'

import { ListingImageProps } from './'
import { AutosListingImageProps } from './AutosListingImage'

type ListingImageWrapperProps = Required<Pick<ListingImageProps, 'size'>> & {
  hasImg: boolean
}

type AutosListingImageWrapperProps = Required<
  Pick<AutosListingImageProps, 'size'>
> & {
  hasImg: boolean
}

const dimensionFromSize = {
  medium: {
    desktop: {
      height: '17.6rem',
      width: '17.6rem',
      icon: '8.8rem',
    },
    mobile: {
      height: '10.4rem',
      width: '10.4rem',
      icon: '8.8rem',
    },
  },
  large: {
    desktop: {
      height: '24rem',
      width: '24rem',
      icon: '12rem',
    },
    mobile: {
      height: '20.8rem',
      width: '29.6rem',
      icon: '14.8rem',
    },
  },
  small: {
    desktop: {
      height: '8rem',
      width: '8rem',
      icon: '0',
    },
    mobile: {
      height: '6.4rem',
      width: '6.4rem',
      icon: '0',
    },
  },
}

const autosDimensionFromSize = {
  // Used by AutosListingCard for default state without description
  small: {
    desktop: {
      height: '21.2883rem',
      width: '27.6483rem',
      icon: '16rem',
    },
    tablet: {
      height: '18.4767rem',
      width: '23.9967rem',
      icon: '16rem',
    },
    mobile: {
      height: '12.3rem',
      width: '16rem',
      icon: '16rem',
    },
    smallMobile: {
      height: '9rem',
      width: ' 11.8rem',
      icon: '16rem',
    },
  },
  // Used by AutosListingCard for isHighlight state without description
  medium: {
    desktop: {
      height: '27.1125rem',
      width: '35.2125rem',
      icon: '16rem',
    },
    tablet: {
      height: '23.0958rem',
      width: '29.9958rem',
      icon: '16rem',
    },
    mobile: {
      height: '16rem',
      width: '20.8rem',
      icon: '16rem',
    },
    smallMobile: {
      height: '12.3rem',
      width: '16rem',
      icon: '16rem',
    },
  },
  // Used by AutosListingCard for default state with description
  xLarge: {
    desktop: {
      height: '21.2rem',
      width: '27.65rem',
      icon: '15rem',
    },
    tablet: {
      height: '18.4rem',
      width: '24rem',
      icon: '12rem',
    },
    mobile: {
      height: '12.27rem',
      width: '16rem',
      icon: '7rem',
    },
    smallMobile: {
      height: '9rem',
      width: '11.75rem',
      icon: '6rem',
    },
  },
  // Used by AutosListingCard for isHighlight state with description
  xxLarge: {
    desktop: {
      height: '27rem',
      width: '35.22rem',
      icon: '16rem',
    },
    tablet: {
      height: '23rem',
      width: '30rem',
      icon: '13rem',
    },
    mobile: {
      height: '15.95rem',
      width: '20.8rem',
      icon: '9rem',
    },
    smallMobile: {
      height: '12.27rem',
      width: '16rem',
      icon: '8rem',
    },
  },
}

export const ListingImageWrapper = styled.div<ListingImageWrapperProps>(
  ({ size, theme, hasImg }) => {
    const dimensions = dimensionFromSize[size]
    return `
      background-color: ${
        hasImg ? theme.colors.grey.light3 : theme.colors.grey.light4
      };
      border-radius: ${theme.borderRadius.medium};
      height: ${dimensions.mobile.height};
      width: ${dimensions.mobile.width};

      & > svg {
        color: ${theme.colors.grey.light2};
        height: ${dimensions.mobile.icon};
        width: auto;
      }

      & img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }

      ${MEDIA_QUERIES(theme).medium}{
        height: ${dimensions.desktop.height};
        width: ${dimensions.desktop.width};   

        & > svg {
          height: ${dimensions.desktop.icon};
        }
      }
    `
  }
)

export const AutosListingImageWrapper =
  styled.div<AutosListingImageWrapperProps>(({ size, theme, hasImg }) => {
    const dimensions = autosDimensionFromSize[size]
    return `
      align-items: center;
      background-color: ${
        hasImg ? theme.colors.grey.light3 : theme.colors.grey.light4
      };
      border-radius: ${theme.borderRadius.medium};
      display: flex;
      width: ${dimensions.smallMobile.width};
      height: ${dimensions.smallMobile.height};
      justify-content: center;
      overflow: hidden;
      position: relative;

      & > svg {
        color: ${theme.colors.grey.light2};
        width: auto;
        height: ${dimensions.smallMobile.icon};
      }

      & img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }

      // Temporary media-query for the AutosListingCard experiment. We'll update the MEDIA_QUERIES once we validate the experiment.
      ${MEDIA_QUERIES(theme).mediumSmall}{
        width: ${dimensions.mobile.width};   
        height: ${dimensions.mobile.height};

        & > svg {
          height: ${dimensions.mobile.icon};
        }
      }

      ${MEDIA_QUERIES(theme).medium}{
        width: ${dimensions.tablet.width};   
        height: ${dimensions.tablet.height};

        & > svg {
          height: ${dimensions.tablet.icon};
        }
      }

      ${MEDIA_QUERIES(theme).xLarge}{
        width: ${dimensions.desktop.width};   
        height: ${dimensions.desktop.height};

        & > svg {
          height: ${dimensions.desktop.icon};
        }
      }
    `
  })

export const ListingImageBadgeWrapper = styled.div(
  ({ theme }) => `
  ${styleFromTypography(theme.typography.body.xSmall)}
  align-items: center;
  background: ${hexToRgb(theme.colors.grey.primary, 0.85)};
  border-radius: 0 ${theme.borderRadius.small} ${theme.borderRadius.small} 0;
  color: ${theme.colors.white};
  left: 0;
  max-width: 90%;
  padding: .3rem ${theme.spacing.defaultSmall};
  position: absolute;
  top: ${theme.spacing.defaultSmall};

  ${MEDIA_QUERIES(theme).medium}{
    ${styleFromTypography(theme.typography.body.small)}
    padding: .6rem ${theme.spacing.defaultSmall};
    top: ${theme.spacing.default};
  }
  `
)

export const BadgeContent = styled.div(
  ({ theme }) => `
  display: flex;
  gap: ${theme.spacing.mini};
  line-height: 1.5;
  `
)
