import { THIN_CONTENT_LENGTH } from '@kijiji/seo/constants'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { type HTMLProps, useCallback, useMemo } from 'react'

type SeoThinContentLinkProps = HTMLProps<HTMLAnchorElement> & {
  content?: string
  href: string
  onClick?: () => void
  children: React.ReactNode
}

/**
 * Component that renders a link which conditionally uses a `<span>` or an `<Link>` element
 * based on the length of the provided content.
 *
 * @param content - Content to be checked for thin content.
 * @param href - URL to navigate to when the link is clicked.
 * @param onClick - Optional click handler for the link.
 * @param children - Children elements to be rendered inside the link.
 * @param rest - Additional props to be spread onto the link element.
 *
 * @returns The rendered link element.
 */
export const SeoThinContentLink = ({
  content,
  href,
  onClick,
  children,
  ...rest
}: SeoThinContentLinkProps) => {
  const isThinContent = useMemo(() => content && content.length < THIN_CONTENT_LENGTH, [content])
  const { push } = useRouter()

  const handleClick = useCallback(() => {
    onClick?.()
    push(href)
  }, [href, onClick, push])

  const handleKeyDown = useCallback(
    (evt: React.KeyboardEvent<HTMLSpanElement>) => {
      if (evt.key === 'Enter' || evt.key === ' ') {
        evt.preventDefault()
        handleClick()
      }
    },
    [handleClick]
  )

  return isThinContent ? (
    <span role="link" tabIndex={0} onClick={handleClick} onKeyDown={handleKeyDown} {...rest}>
      {children}
    </span>
  ) : (
    <Link href={href} onClick={onClick} {...rest}>
      {children}
    </Link>
  )
}
