import { type FC, type PropsWithChildren, useEffect, useState } from 'react'
import { AdvertisingProvider } from 'react-advertising'

import { AdSenseScripts } from '@/features/advertisement/components/adsense/AdSenseScripts'
import { AmazonTamScripts } from '@/features/advertisement/components/amazon-tam/amazonTamScripts'
import { BlockthroughScripts } from '@/features/advertisement/components/blockthrough/BlockthroughScripts'
import { PrebidScripts } from '@/features/advertisement/components/prebid/PrebidScripts'
import { type AdConfig } from '@/features/advertisement/types/adConfig'

type AdsWrapperProps = PropsWithChildren & {
  gptConfig?: AdConfig
  prebidConfig?: { setupPrebid: () => void }[]
  skipAdsense?: boolean
  skipBlockthrough?: boolean
}

export const AdsWrapper: FC<AdsWrapperProps> = ({
  children,
  gptConfig,
  prebidConfig,
  skipAdsense,
  skipBlockthrough,
}) => {
  const [isGptReady, setIsGptReady] = useState(false)

  /**
   * this logic is to ensure that GPT, NWA and React-Advertising are in sync
   * will revisit once we implement openwrap
   */
  useEffect(() => {
    // we need to assure that GPT is ready before passing a config to React-Advertising
    const onGptReady = () => {
      setIsGptReady(true)
    }

    // Check if googletag.apiReady is already true
    if (typeof window !== 'undefined' && window?.googletag?.apiReady) {
      onGptReady()
    } else {
      // Initialize googletag and cmd array if not already done
      window.googletag = window.googletag || { cmd: [] }

      // Push the onGptReady function onto the command queue
      window.googletag.cmd.push(onGptReady)
    }
  }, [])

  return (
    <>
      <AdvertisingProvider config={isGptReady ? gptConfig : undefined} plugins={prebidConfig}>
        {children}
      </AdvertisingProvider>

      <PrebidScripts />
      <AmazonTamScripts />

      {skipAdsense ? null : <AdSenseScripts />}
      {skipBlockthrough ? null : <BlockthroughScripts />}
    </>
  )
}
