import styled from 'styled-components'

import { RequiredPickMap } from '@/ui/typings/helpers'

import { DividerProps } from './Divider'

type DividerContainerProps = RequiredPickMap<
  DividerProps,
  'bottom' | 'size' | 'top'
>

const dividerSizeMap: Record<DividerContainerProps['$size'], string> = {
  small: '.1rem',
  medium: '.4rem',
  large: '.8rem',
}

export const DividerContainer = styled.div<DividerContainerProps>(
  ({ $bottom, $size, $top, theme }) => `
  background-color: ${theme.colors.grey.light4};
  height: ${dividerSizeMap[$size]};
  margin-bottom: ${$bottom};
  margin-top: ${$top};
  width: 100%;
`
)
