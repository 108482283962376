/**
 * Converts our HEX theme colors to RGB format when we need to add transparency to them
 */
export const hexToRgb = (hex: string, transparency: number) => {
  // Remove the '#' if present
  hex = hex.replace(/^#/, '')

  // Parse the hex values
  const bigInt = parseInt(hex, 16)

  // Extract RGB components
  const r = (bigInt >> 16) & 255
  const g = (bigInt >> 8) & 255
  const b = bigInt & 255

  return `rgb(${r}, ${g}, ${b}, ${transparency})`
}
