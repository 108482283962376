// =============================================================================
//
// Amazon Transparent ad Marketplace (TAM) constants
//
// =============================================================================

export type AmazonTamConfig = {
  pubID: string
  bidTimeout: number
  deals: boolean
  adServer: 'googletag'
  simplerGPT: boolean
}

export const AMAZON_TAM_CONFIG: AmazonTamConfig = {
  pubID: '5110',
  bidTimeout: 2e3,
  deals: true,
  adServer: 'googletag',
  simplerGPT: true,
}
