import { getImageBySize } from '@kijiji/image'
import { type FC, type HTMLProps } from 'react'

import { CoreListingCardImage } from '@/components/shared/core-listing-card/styled'
import { IMAGE_NOT_AVAILABLE_URL } from '@/constants/others'

type ListingImageWithFallbackProps = Omit<HTMLProps<HTMLImageElement>, 'ref' | 'as'> & {
  variant?: 'carousel' | 'feed'
}

export const ListingImageWithFallback: FC<ListingImageWithFallbackProps> = ({
  src,
  alt,
  ...props
}) => {
  const listingImageSrc = src ? getImageBySize(src, 300, 'JPG', true) : IMAGE_NOT_AVAILABLE_URL

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = IMAGE_NOT_AVAILABLE_URL
  }
  return <CoreListingCardImage {...props} src={listingImageSrc} alt={alt} onError={handleError} />
}
