// constants for the Homepage Ad slots

import { AD_NETWORK_CODE, AD_PATH_BASE } from '@/features/advertisement/constants/adSlots'
import { type PrebidConfig } from '@/features/advertisement/constants/prebid'
import { type AdSizesMap } from '@/features/advertisement/types/adConfig'

export const HOMEPAGE_GPT_ID = {
  RIGHTRAIL_MARKETING: 'gpt-rightrail-marketing',
  RIGHTRAIL_TOP: 'gpt-rightrail-top',
  RIGHTRAIL_BOTTOM: 'gpt-rightrail-bottom',
  LEADER_BOTTOM: 'gpt-leader-bottom',
  LEADER_BASE: 'gpt-leader-base',
  LEADER_BASE_HOMEFEED: 'gpt-leader-base-homefeed',
  GPT_STICKY: 'gpt-sticky',
}

export const adSizesMapHomepage: AdSizesMap = {
  [HOMEPAGE_GPT_ID.RIGHTRAIL_MARKETING]: [
    [300, 250],
    [300, 400],
  ],
  [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: [
    [300, 250],
    [300, 600],
  ],
  [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: [[728, 90]],
  [HOMEPAGE_GPT_ID.LEADER_BASE]: [[728, 90]],
  [HOMEPAGE_GPT_ID.LEADER_BASE_HOMEFEED]: [
    [320, 50],
    [728, 90],
  ],

  [HOMEPAGE_GPT_ID.GPT_STICKY]: [[320, 50]],
}

/**
 * ad unit path the for homepage
 * this is how GAM determines what ad inventory to serve
 */
export const BASE_AD_PATH_FOR_HP = `/${AD_NETWORK_CODE}/${AD_PATH_BASE}homepage`

/**
 * page test group array to be passed as a GPT targeting value in the ad config
 * based on what we're sending on legacy
 */
export const PTG = [
  'CASINT4862_0',
  'CASINT5487_0',
  'KCA34949_0',
  'KCA38991_0',
  'KCAN1050__1',
  'KCAN10715__1',
  'KCAN13748__1',
  'KCAN15957__0',
  'KCAN17895__1',
  'KCAN1806__1',
  'KCAN18206__1',
  'KCAN18697__1',
  'KCAN19025__2',
  'KCAN20660__1',
  'KCAN20710__0',
  'KCAN21013__1',
  'KCAN3671__0',
  'KCAN4308__-99',
  'KCAN5349__0',
  'KCAN6062__-99',
  'KCAN6084__1',
  'KCAN667_1',
  'KCAN810__1',
  'KCAN909__-99',
  'gpt-rightrail-top_R',
]

export const HOMEPAGE_PREBID_SLOTS: Record<string, PrebidConfig> = {
  RIGHTRAIL_TOP: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477237',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190666',
          size: [300, 250],
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190666',
          size: [300, 600],
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714687',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_HOMEPAGE_TOP_ENGLISH_300x250@300x250',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582464',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'triplelift',
        params: {
          inventoryCode: 'Kijiji_Homepage_Desktop_InFeed_Top',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
      {
        bidder: 'smartadserver',
        params: {
          siteId: '508684',
          pageId: '1599354',
          formatId: '112838',
        },
        responsiveRules: {},
        labels: ['gpt-rightrail-top-1148', 'gpt-rightrail-top-0'],
      },
    ],
    mediaTypes: {
      banner: {
        sizes: [
          [300, 250],
          [300, 600],
        ],
      },
    },
  },
  LEADER_BOTTOM: {
    bids: [
      {
        bidder: 'appnexus',
        params: {
          placementId: '10477240',
        },
        responsiveRules: {},
        labels: ['gpt-leader-bottom-760', 'gpt-leader-bottom-0'],
      },
      {
        bidder: 'ix',
        params: {
          siteId: '190667',
          size: [728, 90],
        },
        responsiveRules: {},
        labels: ['gpt-leader-bottom-760', 'gpt-leader-bottom-0'],
      },
      {
        bidder: 'openx',
        params: {
          delDomain: 'kijiji-d.openx.net',
          unit: '538714688',
        },
        responsiveRules: {},
        labels: ['gpt-leader-bottom-760', 'gpt-leader-bottom-0'],
      },
      {
        bidder: 'pubmatic',
        params: {
          publisherId: '155984',
          adSlot: 'PUBMATIC_HOMEPAGE_BOTTOM_ENGLISH_300x250@300x250',
        },
        responsiveRules: {},
        labels: ['gpt-leader-bottom-760', 'gpt-leader-bottom-0'],
      },
      {
        bidder: 'rubicon',
        params: {
          siteId: '45324',
          accountId: '12230',
          zoneId: '582466',
        },
        responsiveRules: {},
        labels: ['gpt-leader-bottom-760', 'gpt-leader-bottom-0'],
      },
    ],
    mediaTypes: {
      banner: {
        sizes: [[728, 90]],
      },
    },
  },
}
