import { type ReactiveVar, makeVar } from '@apollo/client'

/**
 * This favourite map contains the favourite status of *some* listings. If a listing is not in this
 * map, it is assumed to not be favourited.
 *
 * USE THIS SPARINGLY. This is a reactive var, so it will trigger re-renders when updated. Usage of
 * this is intended for components that need to know the favourite status of a listing, but do not
 * need to re-fetch the data from the server.
 */
export const favouriteMap: ReactiveVar<Record<string, boolean | null>> = makeVar({})
