// =============================================================================
//
// Prebid partners (used only for Prebid)
//
// =============================================================================

import { HOMEPAGE_GPT_ID } from '@/components-page/homepage/advertisement/homepageAdSlots'
import { GPT_COMPONENT_ID } from '@/features/advertisement/constants/adSlots'

export const PARTNER_NEXUS = {
  id: 'appnexus',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      placementId: '10477092',
    },
    'gpt-r2s-vip': {
      placementId: '10477092',
    },
    'gpt-posted-vip': {
      placementId: '10477092',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      placementId: '10477237',
    },
    'gpt-vip-leader': {
      placementId: '10477242',
    },
    GPTBigbox: {
      placementId: '10477243',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      placementId: '10477244',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      placementId: '10477245',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      placementId: '10477246',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      placementId: '18317550',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      placementId: '18317551',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      placementId: '10477247',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      placementId: '10477248',
    },
    [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: {
      placementId: '10477240',
    },
    [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: {
      placementId: '10477244',
    },
    'gpt-vip-footer': {
      placementId: '16687876',
    },
  },
}

export const PARTNER_IX = {
  id: 'ix',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      siteId: '190665',
    },
    'gpt-r2s-vip': {
      siteId: '190665',
    },
    'gpt-posted-vip': {
      siteId: '190665',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      siteId: '190666',
    },
    'gpt-vip-leader': {
      siteId: '190668',
    },
    GPTBigbox: {
      siteId: '190669',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      siteId: '190663',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      siteId: '190670',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      siteId: '190671',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      siteId: '452978',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      siteId: '452979',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      siteId: '190672',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      siteId: '190673',
    },
    [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: {
      siteId: '190667',
    },
    'gpt-vip-footer': {
      siteId: '400482',
    },
  },
}

export const PARTNER_OPENX = {
  id: 'openx',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714686',
    },
    'gpt-r2s-vip': {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714686',
    },
    'gpt-posted-vip': {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714686',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714687',
    },
    'gpt-vip-leader': {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714689',
    },
    GPTBigbox: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714690',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714691',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714692',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714693',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '540955320',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '540955321',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714694',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714696',
    },
    [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714688',
    },
    [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: {
      delDomain: 'kijiji-d.openx.net',
      unit: '538714691',
    },
    'gpt-vip-footer': {
      delDomain: 'kijiji-d.openx.net',
      unit: '540825836',
    },
  },
}

export const PARTNER_PUBMATIC = {
  id: 'pubmatic',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
    },
    'gpt-r2s-vip': {
      publisherId: '155984',
      adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
    },
    'gpt-posted-vip': {
      publisherId: '155984',
      adSlot: 'PUBMATIC_ADFLOW_TOP_ENGLISH_728x90@728x90',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_HOMEPAGE_TOP_ENGLISH_300x250@300x250',
    },
    'gpt-vip-leader': {
      publisherId: '155984',
      adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_TOP_ENGLISH_728x90@728x90',
    },
    GPTBigbox: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_BOTTOM_ENGLISH_300x250@300x250',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_TOP_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE1_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE2_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE3_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_INLINE4_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_BASEBOARD_ENGLISH_728x90@728x90',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_SEARCH_RESULTS_PAGE_LEFT_RAIL_ENGLISH_160x600@160x600',
    },
    [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: {
      publisherId: '155984',
      adSlot: 'PUBMATIC_HOMEPAGE_BOTTOM_ENGLISH_300x250@300x250',
    },
    'gpt-vip-footer': {
      publisherId: '155984',
      adSlot: 'PUBMATIC_VIEW_ITEM_PAGE_BASEBOARD_ENGLISH_728x90@728x90',
    },
  },
}

export const PARTNER_RUBICON = {
  id: 'rubicon',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582460',
    },
    'gpt-r2s-vip': {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582460',
    },
    'gpt-posted-vip': {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582460',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582464',
    },
    'gpt-vip-leader': {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582470',
    },
    GPTBigbox: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582472',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582474',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582476',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582478',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '1549838',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '1549840',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582480',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582482',
    },
    [HOMEPAGE_GPT_ID.LEADER_BOTTOM]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582466',
    },
    [GPT_COMPONENT_ID.MWEB_LEADERBOARD]: {
      siteId: '45324',
      accountId: '12230',
      zoneId: '582474',
    },
    'gpt-vip-footer': {
      siteId: '45324',
      accountId: '12230',
      zoneId: '1390056',
    },
  },
}

export const PARTNER_TRIPLIFT = {
  id: 'triplelift',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
    },
    'gpt-r2s-vip': {
      inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
    },
    'gpt-posted-vip': {
      inventoryCode: 'Kijiji_Adflow_Desktop_InFeed_Top',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      inventoryCode: 'Kijiji_Homepage_Desktop_InFeed_Top',
    },
    'gpt-vip-leader': {
      inventoryCode: 'Kijiji_ViewItem_Desktop_InFeed_Top',
    },
    GPTBigbox: {
      inventoryCode: 'Kijiji_ViewItem_Desktop_InFeed_Bottom',
    },
    'gpt-vip-footer': {
      inventoryCode: 'Kijiji_TRIPLELIFT_VIEW_ITEM_PAGE_BASEBOARD_ENGLISH_HDX',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Top',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      inventoryCode: 'Kijiji_SearchResults_Mobile_inline1',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      inventoryCode: 'Kijiji_SearchResults_Mobile_inline2',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      inventoryCode: 'Kijiji_SearchResults_desktop_inline3',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      inventoryCode: 'Kijiji_SearchResults_desktop_inline4',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_Baseboard',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      inventoryCode: 'Kijiji_SearchResults_Desktop_InFeed_LeftRail',
    },
  },
}

export const PARTNER_SMARTADSERVER = {
  id: 'smartadserver',
  cpmAdjustment: 1,
  params: {
    ImageLeaderBoard: {
      siteId: '508684',
      pageId: '1599357',
      formatId: '112837',
    },
    'gpt-r2s-vip': {
      siteId: '508684',
      pageId: '1599357',
      formatId: '112837',
    },
    'gpt-posted-vip': {
      siteId: '508684',
      pageId: '1599357',
      formatId: '112837',
    },
    [HOMEPAGE_GPT_ID.RIGHTRAIL_TOP]: {
      siteId: '508684',
      pageId: '1599354',
      formatId: '112838',
    },
    'gpt-vip-leader': {
      siteId: '508684',
      pageId: '1599356',
      formatId: '112839',
    },
    GPTBigbox: {
      siteId: '508684',
      pageId: '1599356',
      formatId: '112837',
    },
    [GPT_COMPONENT_ID.TOP_LEADERBOARD]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112843',
    },
    [GPT_COMPONENT_ID.INLINE_1]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112838',
    },
    [GPT_COMPONENT_ID.INLINE_2]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112839',
    },
    [GPT_COMPONENT_ID.INLINE_3]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112841',
    },
    [GPT_COMPONENT_ID.INLINE_4]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112842',
    },
    [GPT_COMPONENT_ID.BASE_LEADERBOARD]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112837',
    },
    [GPT_COMPONENT_ID.SKYSCRAPER]: {
      siteId: '508684',
      pageId: '1599355',
      formatId: '112840',
    },
    'gpt-vip-footer': {
      siteId: '508684',
      pageId: '1599356',
      formatId: '112838',
    },
  },
}

// types for prebid custom config
export type PbPartnerConfig = {
  id: string
  cpmAdjustment: number
  params: Record<string, object>
}

export const PB_PARTNER_CONFIG: PbPartnerConfig[] = [
  PARTNER_NEXUS,
  PARTNER_IX,
  PARTNER_OPENX,
  PARTNER_PUBMATIC,
  PARTNER_RUBICON,
  PARTNER_TRIPLIFT,
  PARTNER_SMARTADSERVER,
]
