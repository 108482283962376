import { type AutosDealerAmountPrice } from '@kijiji/generated/graphql-types'

import { type VipQueryPrice } from '@/types/listings'

export const isAutosDealerAmountPrice = (
  price?: VipQueryPrice
): price is AutosDealerAmountPrice => {
  if (
    !!price &&
    (price.__typename === 'AutosDealerAmountPrice' || 'surcharges' in price || 'msrp' in price)
  ) {
    return true
  }

  return false
}
