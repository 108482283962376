/**
 * Generates the path to a script on the CDN, given an ASSET_PREFIX determined by the build.
 * Falls back to passed in relativeSrc if the prefix is not provided.
 *
 * @param relativeSrc - Relative local path to the script.
 * @returns URL to the script, either on the CDN or relative on the container.
 * @see process.env.ASSET_PREFIX Set by the build, used to determine the path to the assets on the CDN.
 */
export const getScriptSrcWithPrefix = (relativeSrc: string): string =>
  `${
    process.env.ASSET_PREFIX ? `${process.env.ASSET_PREFIX}/_next/static/scripts` : '/next-assets'
  }${relativeSrc}`

/**
 * Generates the path to a media file on the CDN, given an ASSET_PREFIX determined by the build.
 * Falls back to passed in relativeSrc if the prefix is not provided.
 *
 * @param relativeSrc - Relative local path to the media file.
 * @returns URL to the media file, either on the CDN or relative on the container.
 * @see process.env.ASSET_PREFIX Set by the build, used to determine the path to the assets on the CDN.
 */
export const getMediaSrcWithPrefix = (relativeSrc: string) =>
  `${
    process.env.ASSET_PREFIX ? `${process.env.ASSET_PREFIX}/_next/static/media` : '/next-assets'
  }${relativeSrc}`

/**
 * Generates the path to a favicon on the CDN, given an ASSET_PREFIX determined by the build.
 * Falls back to local assets in the container if the prefix is not provided.
 *
 * @param relativeSrc - Relative local path to the favicon.
 * @returns URL to the favicon, either on the CDN or relative on the container.
 * @see process.env.ASSET_PREFIX Set by the build, used to determine the path to the assets on the CDN.
 */
export const getFaviconSrcWithPrefix = (relativeSrc: string) =>
  `${process.env.ASSET_PREFIX ? `${process.env.ASSET_PREFIX}/favicons` : '/favicons'}${relativeSrc}`
