import {
  type AttributeFilter,
  type FilterGroup,
  type MinMax,
  type RangeFilter,
} from '@kijiji/generated/graphql-types'
import { v4 as generateUUID } from 'uuid'

import { CookieRegistry } from '@/constants/cookieRegistry'
import {
  type FilterName,
  type ForSaleBy,
  type GptValue,
  isForSaleBy,
} from '@/features/advertisement/types/adConfig'
import { createCookie } from '@/utils/cookies/createCookie'
import { getCookieByKey } from '@/utils/cookies/getCookieByKey'
import { getMaxSafeExpiryInDays } from '@/utils/cookies/getMaxSafeAgeExpiry'
import { isServer } from '@/utils/isSSR'
import { MACHINE_ID_COOKIE_EXPIRY_MS } from '@/utils/middleware/setMachineIdCookie'

export const getMachIdFromCookie = (): GptValue => {
  if (isServer()) return []

  const machIdCookie = getCookieByKey(document.cookie, CookieRegistry.MACHINE_ID)

  if (!machIdCookie) {
    const machIdUuid = generateUUID()
    createCookie(CookieRegistry.MACHINE_ID, machIdUuid, {
      maxAge: MACHINE_ID_COOKIE_EXPIRY_MS,
      expiryInDays: getMaxSafeExpiryInDays(),
      secure: true,
    })
    return machIdUuid
  }

  return machIdCookie
}

export const priceLabel = (priceMinMax: MinMax | undefined) => {
  if (!priceMinMax) {
    return null
  }

  const { min, max } = priceMinMax

  if (!min && !max) {
    return null
  }

  if (min && !max) {
    return `${min}_or_more`
  }

  if (!min && max) {
    return `${max}_or_less`
  }

  return `${min}_to_${max}`
}

export const yearLabel = (yearFilters: MinMax | undefined) => {
  if (!yearFilters) {
    return null
  }

  const { min, max } = yearFilters

  if (!min && !max) {
    return null
  }

  if (min && !max) {
    return `${min}_or_later`
  }

  if (!min && max) {
    return `${max}_or_earlier`
  }

  return `${min}_to_${max}`
}

type GPTForSaleBy = 'owner' | 'dealer'
export const GPTForSaleByLabel: { [x in ForSaleBy]: GPTForSaleBy } = {
  ownr: 'owner',
  delr: 'dealer',
} as const

export const forSaleByLabel = (selectedValues: string[]): GPTForSaleBy | GPTForSaleBy[] => {
  const selectedValue = selectedValues[0]

  if (selectedValues.length === 1 && isForSaleBy(selectedValue)) {
    return GPTForSaleByLabel[selectedValue]
  }

  return [GPTForSaleByLabel.ownr, GPTForSaleByLabel.delr]
}

type FilterType = AttributeFilter | RangeFilter

export const getFilterSelectedValues = (filters: FilterGroup[], name: FilterName) => {
  const filter = filters.find((filter) => filter.name === name)?.filters[0] as FilterType

  if (filter?.__typename === 'AttributeFilter') {
    return filter.selectedValues
  }

  if (filter?.__typename === 'RangeFilter') {
    return filter.selectedRangeValues
  }

  return []
}

export const singleValueArrayToString = (array: string[]): string | string[] =>
  array.length < 2 ? array.toString() : array

export const getPrebidVersion = () => {
  if (isServer()) return undefined

  const prebidVersion = window.pbjs?.version

  // remove the 'v' from the version string
  return prebidVersion ? prebidVersion.slice(1) : undefined
}

// carmake and carmodel filters are both nested within the 'make' filter
export const extractMakeAndModel = (filters: FilterGroup[]) => {
  const subFilters = filters.find((filter) => filter.name === 'make')?.filters

  const carmakeFilter = subFilters?.find((filter) => filter.name === 'carmake') as FilterType
  const carmodelFilter = subFilters?.find((filter) => filter.name === 'carmodel') as FilterType

  const makeFilters =
    carmakeFilter?.__typename === 'AttributeFilter' ? carmakeFilter.selectedValues : []
  const modelFilters =
    carmodelFilter?.__typename === 'AttributeFilter' ? carmodelFilter.selectedValues : []

  return {
    makeFilters,
    modelFilters,
  }
}
