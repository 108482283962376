import { HTMLAttributes } from 'react'

import { DividerContainer } from './styled'

export type DividerProps = HTMLAttributes<HTMLHRElement> & {
  /**
   * Bottom margin of component
   */
  bottom?: string
  /**
   * Defines the thickness of the component
   */
  size?: 'small' | 'medium' | 'large'
  /**
   * Top margin of component
   */
  top?: string
}

/**
 * @note Accessibility concern: <hr /> has an implicit role="separator"
 * If this component changes to not use that tag, it should explicitly define the role.
 */

export const Divider = ({
  bottom = '0',
  size = 'small',
  top = '0',
  ...rest
}: DividerProps) => {
  return <DividerContainer $bottom={bottom} $size={size} $top={top} {...rest} />
}
